import React from 'react'
import Routes from './routes'
import UserContext from './contexts/UserContext'
import './sass/global.scss'
import useLocalStorage from './hooks/useLocalStorage'

function App () {
  const [ user, setUser ] = useLocalStorage('@informes/user', {})

  return (
    <UserContext.Provider value={{ user, setUser }}>
      <Routes />
    </UserContext.Provider>
  )
}

export default App
