// Map colors
export const orange = {
  200: '#FFDAB7',
  light: '#FF9F33',
  base: '#FF8700',
  extra: '#FF7A00',
  medium: '#EB7000',
  dark: '#FF500F',
  linear: 'linear-gradient(7.17deg, #FF500F 0%, #FF8700 100%)',
}

export const red = {
  base: '#FF2A44',
  light: '#ff5d71',
  dark: '#f6001e',
}

export const white = '#FFFFFF'

export const gray = {
  100: '#f5f5f5',
  200: '#e7e7e7',
  300: '#dee2e6',
  400: '#F5F6FA',
  500: '#E6E7F0',
  550: '#C5C8D4',
  600: '#8C91A9',
  700: '#6A6E81',
  800: '#4B4E5C',
  900: '#212529',
  950: '#161616',
  base: '#58595b',
  light: '#747684',
  medium: '#4B4E5C',
  dark: '#292929',
}

export const grayscale = {
  100: '#F5F6FA',
  200: '#DEDFE4',
  300: '#B6B7BB',
  400: '#6A6C72',
  500: '#161616',
}

export const black = '#000000'
