import React, { /* lazy, */ Suspense } from 'react'
import { BrowserRouter, Switch } from 'react-router-dom'
import Route from './Route'
import LoadingIndicator from '../components/LoadingIndicator'

// Redirect Temporario 302 - SEO
// const Login = lazy(() => import(/* webpackChunName: "login" */ '../pages/Login'))
// const IncomeReport = lazy(() => import(/* webpackChunckName: "income-report" */ '../pages/IncomeReport'))

const Routes = () => {
  const fallback = (
    <div className='min-vh-100 d-flex align-items-center justify-content-center'>
      <LoadingIndicator />
    </div>
  )
  return (
    <Suspense fallback={fallback}>
      <BrowserRouter>
        <Switch>
          <Route
            exact path='/' component={() => {
              window.location.href = 'https://blog.bancointer.com.br/informe-de-rendimentos-do-inter'
              return null
            }} isPrivate
          />
          <Route
            path='/login' component={() => {
              window.location.href = 'https://blog.bancointer.com.br/informe-de-rendimentos-do-inter'
              return null
            }}
          />
          {/* <Route exact path='/' component={IncomeReport} isPrivate />
          <Route path='/login' component={Login} /> */}
        </Switch>
      </BrowserRouter>
    </Suspense>
  )
}

export default Routes
