import React from 'react'

import LoadingIndicatorStyle from './style'

type LoadingIndicatorProps = {
  size?: string;
}

const LoadingIndicator: React.FC<LoadingIndicatorProps> = ({ size }: LoadingIndicatorProps) => {
  return (
    <LoadingIndicatorStyle size={size} width='36' height='36' viewBox='0 0 36 36'>
      <path d='M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831' fill='none' />
    </LoadingIndicatorStyle>
  )
}

LoadingIndicator.defaultProps = {
  size: 'default',
}

export default LoadingIndicator
