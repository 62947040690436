import React /*, { useContext } */ from 'react'
import { Route /*, Redirect */, RouteProps } from 'react-router-dom'

// Types
// import IUserContext from '../types/UserContext'

// Contexts
// import UserContext from '../contexts/UserContext'

interface IRouteWrapperProps extends RouteProps {
  isPrivate?: boolean;
}

const RouteWrapper = ({ component/*, isPrivate */, ...rest }: IRouteWrapperProps) => {
  // Redirect Temporario 302 - SEO

  // const { user }: IUserContext = useContext(UserContext)
  // if (!user?.tokenJWT && isPrivate) {
  //   return <Redirect to='/login' />
  // }

  // if (user?.tokenJWT && !isPrivate) {
  //   return <Redirect to='/' />
  // }

  return (
    <Route {...rest} component={component} />
  )
}

export default RouteWrapper
